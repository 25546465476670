import Connection from '../../Connection';
import { Logger } from '../../logs/Logger';
import { ProtonPacketUtils } from '../../scales/proton/ProtonPacketUtils';
import { isProtonRequest } from '../../scales/proton/requests/IProtonRequest';
import { TerminalEvents } from '../terminal/Events';
import { AndroidEvents } from './Events';

export class AndroidEventListener {
    connection: Connection;

    constructor(connection: Connection) {
        this.connection = connection;
        Object.values(AndroidEvents).forEach((value) => {
            window.addEventListener(value, (ev) => this.eventHandler(ev, value));
        });
    }

    private eventHandler = (event: CustomEvent, eventName: AndroidEvents): void => {
        Logger.debug(event, {}, eventName);
        switch (event.type) {
            case AndroidEvents.KEEP_ALIVE:
                Logger.debug('Keep alive result received from android.');
                break;
            case AndroidEvents.INIT:
                Logger.log('Android init response received.', {}, event.detail);
                localStorage.setItem('android', `${event.detail.appVersion != null ? event.detail.appVersion : 0}`);
                if (+event.detail.appVersion < 4.1)
                    Logger.warn(
                        'The android version installed on this device is outdated and should be updated as soon as possible to avoid unexpected behaviour.'
                    );
                break;
            case AndroidEvents.SCANNER_RFID:
            case AndroidEvents.SCANNER_QR:
            case AndroidEvents.SCANNER:
                window.dispatchEvent(
                    new CustomEvent(TerminalEvents.SCANNER_RESULT, {
                        detail: event.detail.scannerValue
                            .trim()
                            .replace(/[^\x20-\x7E]/g, '')
                            .replace(/\uFFFD/g, '')
                    })
                );
                break;
            case AndroidEvents.PROTON:
                Logger.debug(`Proton event \"${JSON.parse(event.detail.proton).header.action}\" received.`, {}, JSON.parse(event.detail.proton));
                window.dispatchEvent(new CustomEvent(TerminalEvents.PROTON, { detail: JSON.parse(event.detail.proton) }));
                if (isProtonRequest(JSON.parse(event.detail.proton))) {
                    Logger.debug(`Proton event was a request thus sending a response.`, {}, JSON.parse(event.detail.proton));
                    this.connection.broadcast(ProtonPacketUtils.generateEventResponse(JSON.parse(event.detail.proton)));
                }
                break;
            default:
                //Commented because this is flooding the logs
                //Logger.warn('Unknown message received from android.', {}, event);
                break;
        }
    };
}
